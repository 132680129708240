import { Button } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { fetchAddress, secondaryColor } from '../../constant';

export default function EmployeeLocation({
    data,
    fetchRecentAttendance
}) {
    const [center, setCenter] = useState({ lat: data?.latitude, lng: data?.longitude });
    const [address, setAddress] = useState('')

    const containerStyle = {
        width: '100%',
        height: '500px',
    };

    const fetchingAddress = async () => {
        const get_address = await fetchAddress(data?.latitude, data?.longitude)
        if (get_address) {
            setAddress(get_address)
        }
    }

    useEffect(() => {
        fetchingAddress();
    }, [data])

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    <h3 style={{ fontWeight: 500, marginTop: 0 }}>
                        NOTE: This location is based on Today's Attendance of this Employee
                    </h3>

                    <Button
                        sx={{ boxShadow: 'none', background: secondaryColor, ml: 'auto', textTransform: 'capitalize', fontWeight: 500 }}
                        variant="contained"
                        onClick={() => fetchRecentAttendance()}
                    >
                        Refresh
                    </Button>
                </div>
                <LoadScript googleMapsApiKey="AIzaSyD8oFz3vOQ_4-Xsc_Mfv0m6gCBDCE3Hcr4" libraries={['places']}>
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={20}>
                        <Marker position={center} />
                    </GoogleMap>
                </LoadScript>
                <p>Address: {address || ''}</p>
            </div>
        </>
    )
}