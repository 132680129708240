import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { secondaryColor } from '../../../constant';
import { post_data } from '../../../api';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid } from '@mui/material';

const initialFormValues = {
    employee_id: '',
    month: '',
    salary: {
        base_salary: '',
        house_rent_allowance: '',
        dearness_allowance: '',
        conveyance_allowance: '',
        leave_travel_allowance: '',
        medical_allowance: '',
        special_allowance: '',
        bonus: '',
        provident_fund: '',
        professional_tax: '',
    }
};

export default function AddSalaryModal({
    open, setOpen, penalty, employeeData, fetchEmployeeDetails, type, employees, getAllPayrolls, refresh, setRefresh
}) {
    const { user_data } = useSelector(state => state.user);
    const { member_data } = useSelector(state => state.member);
    const [selectedSalary, setSelectedSalary] = React.useState(null)
    const [penaltyAmount, setPenaltyAmount] = React.useState(0);
    const [totalPenalty, setTotalPenalty] = React.useState(0);

    const [formValues, setFormValues] = React.useState(initialFormValues);

    React.useEffect(() => {
        if (employeeData?.payrolls?.length > 0) {
            const payroll = employeeData.payrolls[0];
            setFormValues({
                employee_id: employeeData._id || '',
                month: payroll?.month || '',
                salary: {
                    base_salary: employeeData?.salary?.base_salary || '',
                    house_rent_allowance: '',
                    dearness_allowance: '',
                    conveyance_allowance: '',
                    leave_travel_allowance: '',
                    medical_allowance: '',
                    special_allowance: '',
                    bonus: '',
                    provident_fund: '',
                    professional_tax: '',
                },
                paid_on: null,
            });
        } else {
            setFormValues({ ...initialFormValues, employee_id: employeeData?._id || '', salary: { ...initialFormValues.salary, base_salary: employeeData?.salary?.base_salary || '' } });
        }
    }, [employeeData]);


    // React.useEffect(() => {
    //     setFormValues(prevFormValues => ({ ...prevFormValues, employee_id: employeeData?._id }));
    // }, [employeeData]);

    React.useEffect(() => {
        setFormValues(prevFormValues => ({ ...prevFormValues, salary: { ...prevFormValues.salary, base_salary: selectedSalary || '' } }));
    }, [selectedSalary]);


    const [formErrors, setFormErrors] = React.useState({
        employeeId: false,
        month: false,
        salary: {},
    });

    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        borderRadius: 2,
        border: 'white',
        boxShadow: 24,
        p: 4,
        maxHeight: "80vh",
        overflow: "auto"
    };

    const handleClose = () => {
        setFormValues({
            employeeId: '',
            month: '',
            salary: {
                base_salary: '',
                house_rent_allowance: '',
                dearness_allowance: '',
                conveyance_allowance: '',
                leave_travel_allowance: '',
                medical_allowance: '',
                special_allowance: '',
                bonus: '',
                provident_fund: '',
                professional_tax: '',
            }
        });
        setFormErrors({
            employeeId: false,
            month: false,
            salary: {},
        });
        setOpen(false);
    };

    const handleChange = (field) => (event) => {
        const { value } = event.target;
        setFormValues({ ...formValues, [field]: value });
        setFormErrors({ ...formErrors, [field]: value.trim() === '' });
    };

    const handleEmployeeSelect = (event, newValue) => {
        setFormValues({ ...formValues, employee_id: newValue._id });
        setFormErrors({ ...formErrors, employee_id: !newValue });
        setSelectedSalary(newValue?.salary?.base_salary);
        setPenaltyAmount(newValue?.penalty_amount);
        setTotalPenalty(newValue?.totalPenalties);
    };

    const handleChangeSalary = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
            ...prev,
            salary: {
                ...prev.salary,
                [name]: value,
            },
        }));

        setFormErrors((prev) => ({
            ...prev,
            salary: {
                ...prev.salary,
                [name]: value.trim() === '' || isNaN(value),
            },
        }));
    };

    const calculateNetSalary = () => {
        const {
            base_salary,
            house_rent_allowance,
            dearness_allowance,
            conveyance_allowance,
            leave_travel_allowance,
            medical_allowance,
            special_allowance,
            bonus,
            provident_fund,
            professional_tax,
        } = formValues.salary;

        const salaryComponents = [
            base_salary,
            house_rent_allowance,
            dearness_allowance,
            conveyance_allowance,
            leave_travel_allowance,
            medical_allowance,
            special_allowance,
            bonus,
        ].map((item) => parseFloat(item) || 0);

        const deductions = (parseFloat(provident_fund) || 0) + (parseFloat(professional_tax) || 0);

        const totalSalary = salaryComponents.reduce((acc, curr) => acc + curr, 0);
        const netSalary = totalSalary - deductions;

        return netSalary;
    };


    const handleSubmit = async () => {
        if (formValues?.paid_on == null) {
            toast.error('Please select Paid Date');
            return;
        }

        const netSalary = calculateNetSalary();
        const payload = {
            ...formValues,
            salary: {
                ...formValues.salary,
                net_salary: netSalary,
            },
            company_id: user_data?._id,
            member_id: member_data?.is_member ? member_data?._id : null,
            status: member_data?.is_member ? 'unpaid' : 'paid',
            is_approved: member_data?.is_member ? false : true
        };


        let response = await post_data('payroll/create-payroll', payload);
        if (response?.status === true) {
            if (type === 'payrollTable') {
                getAllPayrolls()
            }
            else {
                fetchEmployeeDetails();
            }
            toast.success('Salary added successfully!');
            handleClose();
        } else {
            toast.error('Something went wrong!');
            handleClose();
        }
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Salary Details
                    </Typography>
                    <Box component="form" sx={{ mt: 2, width: '100%' }}>
                        {type === 'payrollTable' && (
                            <Autocomplete
                                options={employees}
                                getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                                onChange={handleEmployeeSelect}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Employee"
                                        error={formErrors.employeeId}
                                        helperText={formErrors.employeeId ? 'Employee selection is required' : ''}
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                            />
                        )}

                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Month"
                            value={formValues.month}
                            onChange={handleChange('month')}
                            error={formErrors.month}
                            helperText={formErrors.month ? 'Month is required' : ''}
                        >
                            {months.map((month) => (
                                <MenuItem key={month} value={month}>
                                    {month}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Salary Fields */}
                        {Object.keys(formValues.salary).map((key) => (
                            <>
                                <TextField
                                    key={key}
                                    fullWidth
                                    margin="normal"
                                    label={key.replace('_', ' ')}
                                    name={key}
                                    value={formValues.salary[key]}
                                    onChange={handleChangeSalary}
                                    error={formErrors.salary[key]}
                                    helperText={formErrors.salary[key] ? `${key} is required and must be a valid number` : ''}
                                />
                                {
                                    key === 'base_salary' && formValues?.employee_id &&
                                    <>
                                        <Typography variant='body2'>Total Penalities: {penalty?.totalPenalty || totalPenalty}</Typography>
                                        <Typography variant='body2'>Penalty Amount: ₹{penalty?.penalty_amount || penaltyAmount}</Typography>
                                    </>
                                }

                            </>
                        ))}

                        {/* <Grid item xs={12} sx={{ mt: 2 }}>
                            <Box sx={{ width: '100%' }}>
                            </Box>
                        </Grid> */}


                        <TextField
                            fullWidth
                            margin="normal"
                            label="Net Salary"
                            value={calculateNetSalary()}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={formValues.paid_on}
                                onChange={(newValue) => {
                                    setFormValues({ ...formValues, paid_on: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        margin="normal"
                                        label="Paid on"
                                    />
                                )}
                            />
                        </LocalizationProvider>


                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, boxShadow: 'none', background: secondaryColor, padding: '10px 0' }}
                            onClick={handleSubmit}
                        >
                            Pay now
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}